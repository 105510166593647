
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class AlertCell extends Cell {
    get background () {
      const { item: { background } } = this
      return background
    }

    get color () {
      const { item: { color } } = this
      return color
    }

    get icon () {
      const { item: { icon } } = this
      return icon
    }

    get tooltip () {
      const { item: { tooltip } } = this
      return tooltip
    }

    get value () {
      const { item: { value = 0 } } = this
      return value
    }
  }
